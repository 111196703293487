import DataApi from 'fm-data-api'

export const getImage = {
  methods: {
    async getImage (recordId) {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      return await this.dataApi.getRecord('W_Photo', recordId)
        .then((response) => {
          return response[0].fieldData.d_image
        })
    }
  }
}
