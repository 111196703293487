//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from 'fm-data-api'
import { ButtonPlugin, ModalPlugin } from 'bootstrap-vue'
import { getImage } from '../../mixins/getImage'

Vue.use(ButtonPlugin)
Vue.use(ModalPlugin)

export default {
  name: 'Bien',
  components: {
    Simulateur: () => import('../../components/Simulateur'),
    PictoArrow: () => import('../../components/PictoArrow'),
    PictoNetwork: () => import('../../components/PictoNetwork'),
    PictoPrint: () => import('../../components/PictoPrint'),
    BienPreview: () => import('../../components/BienPreview.vue'),
    BienPhotosGallery: () => import('../../components/BienPhotosGallery.vue'),
    BienAlertEmail: () => import('../../components/BienAlertEmail.vue')
  },
  mixins: [getImage],
  data () {
    return {
      bien: {},
      d_image: '',
      photos: [],
      biensAnnexes: {}
    }
  },
  computed: {
    mailTo: function () {
      return encodeURI(`mailto:?subject=${this.bien.d_type} proposé par la société Tolus immo&body=Bonjour, voici un bien intéressant : ${window.location.href}`)
    }
  },
  props: {
    bienRecordId: String
  },
  methods: {
    async loadBien () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      const dataApi = new DataApi(options)
      dataApi.getRecord('W_AnnonceDetail', this.bienRecordId, [{ name: 'photos', limit: 4 }])
        .then((response) => {
          this.bien = response[0].fieldData
          const bienPhotosL = JSON.parse(JSON.stringify(response[0].portalData[Object.keys(response[0].portalData)]))
          if (bienPhotosL.length > 0) {
            this.insertBienPhotos(bienPhotosL)
          }
          const query = this.buildRequest()
          const sort = [
            {
              fieldName: 'd_prix',
              sortOrder: 'descend'
            },
            {
              fieldName: 'd_dateAnnonce',
              sortOrder: 'descend'
            }
          ]
          dataApi.findRecords('W_Annonce', query, sort, '', '3')
            .then((response) => {
              this.insertBiensAnnexes(response)
            })
        })
    },
    buildRequest () {
      const nbrPieces = parseInt(this.bien.d_nbrPieces * 0.8)
      const surface = parseInt(this.bien.d_surface * 0.8)
      const budget = parseInt(this.bien.d_prix * 0.8)
      const arrayFields = [
        { fieldname: 'd_type', fieldvalue: `==${this.bien.d_type}` },
        { fieldname: 'd_nbrPieces', fieldvalue: `>=${nbrPieces}` },
        { fieldname: 'd_surface', fieldvalue: `>=${surface}` },
        { fieldname: 'd_prix', fieldvalue: `>=${budget}` }
      ]
      const query = []
      query.push({ fields: arrayFields })
      return query
    },
    insertBienPhotos (bienPhotosL) {
      this.photos = []
      bienPhotosL.forEach(function (value, index) {
        const photo = Vue.removeLayout(value)
        this.getImage(photo.recordId)
          .then(res => {
            const ph = {}
            ph.d_image = res
            ph.d_imageAlt = photo.d_imageAlt
            this.photos.push(ph)
          })
      }, this)
    },
    insertBiensAnnexes (biensAnnexesL) {
      const biensAnnexesList = (biensAnnexesL === undefined) ? [] : biensAnnexesL
      if (biensAnnexesList.length > 0) {
        const biensToInsert = {}
        biensAnnexesList.forEach(function (value, index) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          biensToInsert[index] = dataToInsert
        })
        this.biensAnnexes = biensToInsert
      } else {
        this.biensAnnexes = {}
      }
    },
    imprimer () {
      window.print()
    },
    classStatus (value) {
      // "En vente" + "Sous offre" + "Sous compromis" + "Vendu"
      if (value !== '' && value !== undefined) {
        return this.$options.filters.camelCase(value)
      }
      return ''
    },
    classConso (value) {
      if (value === '') {
        return 'na'
      }
      if ((value >= 0) && (value <= 50)) {
        return 'a'
      }
      if ((value > 50) && (value <= 90)) {
        return 'b'
      }
      if ((value > 90) && (value <= 150)) {
        return 'c'
      }
      if ((value > 150) && (value <= 230)) {
        return 'd'
      }
      if ((value > 230) && (value <= 330)) {
        return 'e'
      }
      if ((value > 330) && (value <= 450)) {
        return 'f'
      }
      if (value > 450) {
        return 'g'
      }
    },
    classGES (value) {
      if (value === '') {
        return 'na'
      }
      if ((value >= 0) && (value <= 5)) {
        return 'a'
      }
      if ((value > 5) && (value <= 10)) {
        return 'b'
      }
      if ((value > 10) && (value <= 20)) {
        return 'c'
      }
      if ((value > 20) && (value <= 35)) {
        return 'd'
      }
      if ((value > 35) && (value <= 55)) {
        return 'e'
      }
      if ((value > 55) && (value <= 80)) {
        return 'f'
      }
      if (value > 80) {
        return 'g'
      }
    },
    classNa (value) {
      if (value === '') {
        return 'na'
      }
      return ''
    }
  },
  watch: {
    // call the method if the route changes
    $route: {
      handler: 'loadBien',
      immediate: true // runs immediately with mount() instead of calling method on mount hook
    }
  }
}
