//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Assurance',
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data () {
    return {
      greenHeaderMessage: {
        titre: 'Particulier - Indépendant - Entreprises : Auditer, Conseiller & prescrire le contrat approprié à votre situation',
        sousTitre: ''
      }
    }
  }
}
