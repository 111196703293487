//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataApi from 'fm-data-api'

export default {
  name: 'Agence',
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data () {
    return {
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      collaborateurs: {},
      focus: {}
    }
  },
  methods: {
    async getFocus () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      await this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': 'Agence', 'foc_rbq_SOC__Societe::d_nomSociete': 'Tolus Immo' }, '', '', '')
        .then((focusList) => {
          focusList.forEach(function (focus) {
            this.$set(this.focus, focus.fieldData.d_type, {
              titre: focus.fieldData.d_titre,
              texte: focus.fieldData.d_texte,
              theme: focus.fieldData.d_theme,
              image: focus.fieldData['foc_PHOP__PhotoPrincipale::d_image']
            })
          }, this)

          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
        })
    },
    async loadCollaborateurs () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      this.dataApi.findRecords('W_Collaborateur', { 'col_SOC__Societe::d_nomSociete': 'Tolus Immo' }, '', '', '')
        .then((response) => {
          this.insertCollaborateurs(response)
        })
    },
    insertCollaborateurs (collaborateursList) {
      if (collaborateursList.length > 0) {
        const collaborateursToInsert = {}
        collaborateursList.forEach(function (value, index) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          collaborateursToInsert[dataToInsert.ordre] = dataToInsert
        })
        this.collaborateurs = collaborateursToInsert
      } else {
        this.collaborateurs = {}
      }
    }
  },
  created () {
    this.loadCollaborateurs()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getFocus())
  }
}
