import DataApi from 'fm-data-api'

export const getFocus = {
  data: function () {
    return {
      focus: []
    }
  },
  methods: {
    async getFocus (societe, rubrique) {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      return await this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': rubrique, 'foc_rbq_SOC__Societe::d_nomSociete': societe }, '', '', '')
        .then((focusList) => {
          const focusResponse = {}
          focusList.forEach(function (focus) {
            focusResponse[focus.fieldData.d_type] = {
              titre: focus.fieldData.d_titre,
              texte: focus.fieldData.d_texte,
              theme: focus.fieldData.d_theme,
              image: focus.fieldData['foc_PHOP__PhotoPrincipale::d_image']
            }
          })
          return focusResponse
        })
    }
  }
}
