//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import DataApi from 'fm-data-api'
import { NavbarPlugin } from 'bootstrap-vue'

Vue.use(NavbarPlugin)

export default {
  name: 'Home',
  components: {
    BienPreview: () => import('../components/BienPreview.vue'),
    FocusPreview: () => import('../components/FocusPreview.vue')
  },
  data () {
    return {
      dataApi: {},
      focus: [],
      focusToInsert: [],
      biens: {},
      token: '',
      descriptif: ''
    }
  },
  computed: {
    label () {
      return ': ' + this.favoriteColor
    }
  },
  methods: {
    async getFocus () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      await this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': 'Accueil' }, '', '', '')
        .then((focusList) => {
          focusList.forEach(function (focus) {
            this.$set(this.focus, focus.fieldData['foc_rbq_SOC__Societe::d_nomSociete'], {
              titre: focus.fieldData.d_titre,
              texte: focus.fieldData.d_texte,
              theme: focus.fieldData.d_theme,
              image: focus.fieldData['foc_PHOP__PhotoPrincipale::d_image']
            })
          }, this)
        })
    },
    async getFocusAndAnnonces (zkpAdvert) {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      this.dataApi.findRecords('W_Focus', { b_accueil: '1', 'foc_RBQ__Rubrique::d_nomRubrique': 'info' }, '', '', '4')
        .then((focusList) => {
          this.insertFocus(focusList)
        })

      const sort2 = [{
        fieldName: 'd_dateAnnonce',
        sortOrder: 'descend'
      }]
      this.dataApi.getRecords('W_Annonce', sort2, '', '3')
        .then((biensList) => {
          this.insertBiens(biensList)
        })
    },
    insertBiens (biensL) {
      const biensList = (biensL === undefined) ? [] : biensL

      if (Object.values(biensList).length > 0) {
        const biensToInsert = {}
        Object.entries(biensList).forEach(function ([index, value]) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          biensToInsert[index] = dataToInsert
        })
        this.biens = biensToInsert
      } else {
        this.biens = {}
      }
    },
    insertFocus (focusL) {
      const focusList = (focusL === undefined) ? [] : focusL
      if (focusList.length > 0) {
        const focusToInsert = {}
        focusList.forEach(function (value, index) {
          const dataToInsert = Vue.removeLayout(value.fieldData)
          dataToInsert.recordId = value.recordId
          focusToInsert[index] = dataToInsert
        }, this)
        this.focusToInsert = focusToInsert
      } else {
        this.focusToInsert = {}
      }
    }
  },
  beforeMount () {
    this.getFocusAndAnnonces()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getFocus())
  }
}
