import 'regenerator-runtime'
import Vue from 'vue'
import removeFMLayout from './plugins/removeFMLayout'
import alertMailParam from './plugins/alertMailParam'
import { registerFilters } from './filter'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { Select, Slider } from 'element-ui'

registerFilters()

const dbAddress = 'fms.cgcourtage.fr/fmi/data' // 'fmp.editomac.net/fmi/data' // fmp.lyje.eu/fmi/data fmp.editomac.net

Vue.config.productionTip = false
Vue.prototype.$apiUrl = 'https://' + dbAddress
Vue.prototype.$databaseName = 'Tampon'
Vue.prototype.$formDatabaseName = 'cgform'

function getToken () {
  Vue.prototype.$token = axios
    .get('/api/getToken.main')
    .then(response => {
      return response.data.response.token
    })
    .catch(error => {
      console.log(error)
    })
  Vue.prototype.$formToken = axios
    .get('/api/getToken.form')
    .then(response => {
      return response.data.response.token
    })
    .catch(error => {
      console.log(error)
    })
}

getToken()

Vue.prototype.$isLogged = false

Vue.use(Select)
Vue.use(Slider)
Vue.use(removeFMLayout)
Vue.use(alertMailParam)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
