//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getFocus } from '../../mixins/getFocus'
import DataApi from 'fm-data-api'

export default {
  name: 'Equipe',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader'),
    Temoignage: () => import('../../components/Temoignage.vue')
  },
  data () {
    return {
      greenHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      focus: [],
      collaborateurs: {}
    }
  },
  methods: {
    async loadCollaborateurs () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      this.dataApi.findRecords('W_Collaborateur', { 'col_SOC__Societe::d_nomSociete': 'CG Courtage' }, '', '', '')
        .then((response) => {
          this.insertCollaborateurs(response)
        })
    },
    insertCollaborateurs (collaborateursList) {
      if (collaborateursList.length > 0) {
        const collaborateursToInsert = {}
        collaborateursList.forEach(function (value, index) {
          const dataToInsert = value.fieldData
          dataToInsert.recordId = value.recordId
          collaborateursToInsert[dataToInsert.ordre] = dataToInsert
        })
        this.collaborateurs = collaborateursToInsert
      } else {
        this.collaborateurs = {}
      }
    }
  },
  async beforeMount () {
    await this.getFocus('CG Courtage', 'Équipe')
      .then(res => {
        this.focus = res
        this.greenHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
        this.greenHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
      })
  },
  created () {
    this.loadCollaborateurs()
  }
}

