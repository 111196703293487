//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import DataApi from 'fm-data-api'

export default {
  name: 'Investir',
  components: {
    ColorPointsHeader: () => import('../../components/ColorPointsHeader')
  },
  data: function () {
    return {
      focus: {},
      pinkHeaderMessage: {
        titre: '',
        sousTitre: ''
      }
    }
  },
  methods: {
    async getFocus () {
      const options = {
        apiUrl: this.$apiUrl,
        databaseName: this.$databaseName,
        token: await this.$token
      }
      this.dataApi = new DataApi(options)
      await this.dataApi.findRecords('W_Focus', { 'foc_RBQ__Rubrique::d_nomRubrique': 'Investir', 'foc_rbq_SOC__Societe::d_nomSociete': 'Tolus Immo' }, '', '', '')
        .then((focusList) => {
          focusList.forEach(function (focus) {
            this.$set(this.focus, focus.fieldData.d_type, {
              titre: focus.fieldData.d_titre,
              texte: focus.fieldData.d_texte,
              theme: focus.fieldData.d_theme
            })
          }, this)

          this.pinkHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
          this.pinkHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.getFocus())
  }
}
